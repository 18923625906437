const translateDefault = (key: string) => key;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ACCESS_RULES = (t: any = translateDefault) => {
  return {
    sales: {
      title: t("sales"),
      isOptional: true,
      accessRules: {
        customers: {
          title: t("customers"),
          alwaysViewable: true,
          permissions: ["view", "create", "edit", "delete"],
        },
        salesQuotations: {
          title: t("sales-quotations"),
          permissions: ["view", "create", "delete"],
        },
        invoices: {
          title: t("invoices"),
          permissions: ["view", "create", "confirm", "delete"],
        },
        receipts: {
          title: t("receipts"),
          permissions: ["view", "create", "confirm", "delete"],
        },
        creditNotes: {
          title: t("credit-notes"),
          permissions: ["view", "create", "confirm", "delete"],
        },
        other: {
          title: t("other-permissions"),
          permissions: ["allowPosPriceEditing", "allowPosDiscountEditing"],
        },
      },
    },
    purchasing: {
      title: t("purchasing"),
      isOptional: true,
      accessRules: {
        vendors: {
          title: t("vendors"),
          alwaysViewable: true,
          permissions: ["view", "create", "edit", "delete"],
        },
        purchaseOrders: {
          title: t("purchase-orders"),
          permissions: ["view", "create", "delete"],
        },
        invoices: {
          title: t("invoices"),
          permissions: ["view", "create", "confirm", "delete"],
        },
        receipts: {
          title: t("receipts"),
          permissions: ["view", "create", "confirm", "delete"],
        },
        debitNotes: {
          title: t("debit-notes"),
          permissions: ["view", "create", "confirm", "delete"],
        },
      },
    },
    products: {
      title: t("products-and-invento"),
      isOptional: true,
      accessRules: {
        productCategories: {
          title: t("product-categories"),
          alwaysViewable: true,
          permissions: ["view", "create", "edit", "delete"],
        },
        products: {
          title: t("products"),
          alwaysViewable: true,
          permissions: ["view", "create", "edit"],
        },
        inventoryStores: {
          title: t("inventory-stores"),
          alwaysViewable: true,
          permissions: ["view", "create", "edit", "delete"],
        },
        inventoryConsumptions: {
          title: t("inventory-consumptio"),
          permissions: ["view", "create", "confirm", "delete"],
        },
        inventoryStocktakes: {
          title: t("inventory-stocktakes"),
          permissions: ["view", "create", "edit", "confirm", "delete"],
        },
        inventoryTransfers: {
          title: t("inventory-transfers"),
          permissions: ["view", "create", "confirm", "delete"],
        },
      },
    },
    assets: {
      title: t("fixed-assets"),
      isOptional: true,
      accessRules: {
        fixedAssets: {
          title: t("fixed-assets"),
          // alwaysViewable: true,
          permissions: ["view", "create", "confirm", "delete", "edit"],
        },
        fixedAssetTransactions: {
          title: t("fixed-asset-transact"),
          // alwaysViewable: true,
          permissions: ["view", "create", "confirm", "delete"],
        },
      },
    },
    accounting: {
      title: t("advanced-accounting"),
      isOptional: true,
      accessRules: {
        accounts: {
          title: t("accounts"),
          alwaysViewable: true,
          permissions: ["view", "create", "edit", "delete"],
        },
        manualJournals: {
          title: t("manual-journals"),
          permissions: ["view", "create", "edit", "confirm", "delete"],
        },
        generalReceipts: {
          title: t("general-receipts"),
          permissions: ["view", "create", "confirm", "delete"],
        },
      },
    },
    reports: {
      title: t("reports"),
      isOptional: true,
      accessRules: {},
    },
  } as const;
};

export type ACCESS_RULES = ReturnType<typeof ACCESS_RULES>;

export type ACCESS_RULES_KEY = keyof ACCESS_RULES;

export type SectionDetails<T extends ACCESS_RULES_KEY = ACCESS_RULES_KEY> =
  ACCESS_RULES[T];

export type AccessRules = {
  [sectionKey: string]: SectionDetails;
};

// extract the subKey from the accessRules object based on the key
type subKey<T extends ACCESS_RULES_KEY> = T extends T
  ? keyof ACCESS_RULES[T]["accessRules"]
  : never;

// extract the permission value (permissions[]) from the accessRules nested object based on the key and subKey
// !note: it returns all possible permissions values ["view", "create", "edit", "confirm", "delete"]
// todo later: fix this to return only the permissions that are available for each section
type permissionKey<
  T extends ACCESS_RULES_KEY,
  K extends subKey<T> = subKey<T>,
> = ACCESS_RULES[T]["accessRules"][K] extends {
  permissions: readonly string[];
}
  ? ACCESS_RULES[T]["accessRules"][K]["permissions"][number]
  : never;

export type PermissionRule<T extends ACCESS_RULES_KEY = ACCESS_RULES_KEY> =
  T extends T
    ?
        | `${T}.${subKey<T> & string}.${permissionKey<T> & string}`
        | `${T}.isEnabled`
    : never;

type UserRole = {
  orgId: string;
  isOwner: boolean;
  isManager: boolean;
  isReadonly?: boolean | null | undefined;
  memberRoles?:
    | {
        accessRules: Record<string, Record<string, Record<string, boolean>>>;
      }[]
    | null;
};

export const checkPermissions = (
  permission: PermissionRule,
  role: UserRole
) => {
  // if owner or manager or has pos access (without required permission) short circuit
  if (role.isOwner || role.isManager) {
    return true;
  }

  if (role.memberRoles == null || role.memberRoles.length === 0) return false;

  const [moduleKey, subModuleKey, action] = permission.split(".");

  // if the action is not defined, check if the module and subModule are enabled account for the isEnabled key
  return role.memberRoles.some(({ accessRules }) =>
    action == null
      ? !!accessRules?.[moduleKey]?.[subModuleKey]
      : !!accessRules?.[moduleKey]?.[subModuleKey]?.[action]
  );
};
