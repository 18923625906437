import { z } from "zod";
import {
  bilingualTextSchema,
  translationSchema,
  taxNumberSchema,
  nonEmptyStringSchema,
  stringNumbersSchema,
  stringNumbersSchemaRefinement,
  emptyStringSchema,
  zatcaTextSchema,
} from ".";
import sanitizeHtml from "sanitize-html";

export const setupOrganizationArgsSchema = z.object({
  name: nonEmptyStringSchema,
  currency: z.string(),
  crNumber: stringNumbersSchema
    .length(10)
    .superRefine(stringNumbersSchemaRefinement)
    .nullish(),
  taxNumber: taxNumberSchema,
  countryId: z.string(),
});

export const organizationsSetInputSchema = setupOrganizationArgsSchema
  .omit({ currency: true, countryId: true })
  .extend({
    address: zatcaTextSchema.nullable(),
    logoUrl: z.string().nullable(),
    enLogoUrl: z.string().nullable(),
    arLogoUrl: z.string().nullable(),
    posLogoUrl: z.string().nullable(),
    displayName: zatcaTextSchema,
    email: z.string().nullable(),
    phone: z.string().nullable(),
    website: z.string().nullable(),
    addressTranslations: translationSchema,
    displayNameTranslations: translationSchema,
  })
  .partial();

export const organizationsAdminSetInputSchema =
  organizationsSetInputSchema.extend({
    deletedAt: z.string().datetime(),
    isActive: z.boolean(),
    isArchived: z.boolean(),
    isStale: z.boolean(),
  });

const organizationRolesProperties = z.object({
  isEnabled: z.boolean().optional(),
});
export const organizationRolesAccessRulesProperties = z.object({
  create: z.boolean().optional(),
  delete: z.boolean().optional(),
  edit: z.boolean().optional(),
  confirm: z.boolean().optional(),
  view: z.boolean().optional(),
});

export type OrganizationRolesAccessRulesProperties = z.infer<
  typeof organizationRolesAccessRulesProperties
>;

export const organizationAccessRoles = z.object({
  sales: organizationRolesProperties.extend({
    customers: organizationRolesAccessRulesProperties,
    salesQuotations: organizationRolesAccessRulesProperties,
    invoices: organizationRolesAccessRulesProperties,
    receipts: organizationRolesAccessRulesProperties,
    creditNotes: organizationRolesAccessRulesProperties,
    other: z.object({
      allowPosPriceEditing: z.boolean().optional(),
      allowPosDiscountEditing: z.boolean().optional(),
    }),
  }),
  purchasing: organizationRolesProperties.extend({
    vendors: organizationRolesAccessRulesProperties,
    purchaseOrders: organizationRolesAccessRulesProperties,
    invoices: organizationRolesAccessRulesProperties,
    receipts: organizationRolesAccessRulesProperties,
    debitNotes: organizationRolesAccessRulesProperties,
  }),
  products: organizationRolesProperties.extend({
    productCategories: organizationRolesAccessRulesProperties,
    products: organizationRolesAccessRulesProperties,
    inventoryStores: organizationRolesAccessRulesProperties,
    inventoryConsumptions: organizationRolesAccessRulesProperties,
    inventoryStocktakes: organizationRolesAccessRulesProperties,
    inventoryTransfers: organizationRolesAccessRulesProperties,
  }),
  assets: organizationRolesProperties.extend({
    fixedAssets: organizationRolesAccessRulesProperties,
    fixedAssetTransactions: organizationRolesAccessRulesProperties,
  }),
  accounting: organizationRolesProperties.extend({
    accounts: organizationRolesAccessRulesProperties,
    manualJournals: organizationRolesAccessRulesProperties,
    generalReceipts: organizationRolesAccessRulesProperties,
  }),
  reports: organizationRolesProperties,
});

export type OrganizationAccessRoles = z.infer<typeof organizationAccessRoles>;

export const organizationRolesInsertInputSchema = z.object({
  name: nonEmptyStringSchema,
  description: z.string().or(emptyStringSchema),
  accessRules: organizationAccessRoles,
});

export type OrganizationRolesInsertInputSchema = z.infer<
  typeof organizationRolesInsertInputSchema
>;

export const organizationRolesSetInputSchema =
  organizationRolesInsertInputSchema;

const inviteMemberActionArgsSchemaProps = z.object({
  hasPosAccess: z.boolean(),
  inviteEmail: z.string().email(),
  isManager: z.boolean(),
  isReadonly: z.boolean(),
  memberRoles: z.array(z.string().uuid()),
  name: bilingualTextSchema.min(1).max(50),
  notes: bilingualTextSchema.or(emptyStringSchema),
});

export const inviteMemberActionArgsSchema =
  inviteMemberActionArgsSchemaProps.superRefine((data, ctx) => {
    if (!data.isManager && !data.memberRoles.length) {
      ctx.addIssue({
        code: "too_small",
        type: "array",
        minimum: 1,
        inclusive: true,
        path: ["memberRoles"],
      });
    }
    return data;
  });

export const organizationMembersSetInputSchema =
  inviteMemberActionArgsSchemaProps
    .omit({
      inviteEmail: true,
    })
    .extend({
      isSuspended: z.boolean(),
    });

export const organizationReportsSettingsConfig = z.object({
  exemptLocalTaxPurchasing: z.string().uuid().nullish(),
  exemptLocalTaxSales: z.string().uuid().nullish(),
  exportsTaxSales: z.string().uuid().nullish(),
  healthEduLocalTaxSales: z.string().uuid().nullish(),
  importsCustomsTaxPurchasing: z.string().uuid().nullish(),
  importsDeductibleTaxPurchasing: z.string().uuid().nullish(),
  mainTaxPurchasing: z.string().uuid().nullish(),
  mainTaxSales: z.string().uuid().nullish(),
  zeroLocalTaxPurchasing: z.string().uuid().nullish(),
  zeroLocalTaxSales: z.string().uuid().nullish(),
});
export type OrganizationReportsSettingsConfig = z.infer<
  typeof organizationReportsSettingsConfig
>;

export const organizationReportsSettingsInsertInputSchema = z.object({
  readableId: z.string(),
  config: organizationReportsSettingsConfig,
});

export const organizationReportsSettingsSetInputSchema =
  organizationReportsSettingsInsertInputSchema.pick({ config: true }).partial();

export const posSettingsSetInputSchema = z.object({
  printHeaderNotes: z
    .string()
    .transform((val) =>
      sanitizeHtml(val, { allowedTags: [], allowedAttributes: {} })
    )
    .nullish(),
  printFooterNotes: z
    .string()
    .transform((val) =>
      sanitizeHtml(val, { allowedTags: [], allowedAttributes: {} })
    )
    .nullish(),
});

export const transferOrgActionArgsSchema = z
  .object({
    orgId: z.string().uuid(),
    fromUserEmail: z.string().email(),
    toUserEmail: z.string().email(),
  })
  .superRefine((data, ctx) => {
    if (data.fromUserEmail === data.toUserEmail) {
      ctx.addIssue({
        code: "custom",
        message: "fromUserEmail and toUserEmail must be different",
        path: ["fromUserEmail", "toUserEmail"],
      });
    }
  });
