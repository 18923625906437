import type { PlansFlagsSchema } from "@mono/validation/lib/Plans";
import type { APIOutputs } from "@/composables/useTrpcClient";

export type OrganizationDetails = APIOutputs["organizations"]["getOne"]["data"];

export type OrganizationMember =
  APIOutputs["organizations"]["getMetadata"]["data"]["organizationMembers"][number];

export type OrganizationAccount =
  APIOutputs["accounts"]["getAll"]["data"][number];

export type OrganizationReceiptPaymentMethod =
  APIOutputs["organizations"]["getMetadata"]["data"]["receiptPaymentMethods"][number];

export type OrganizationReportingDimension =
  APIOutputs["organizations"]["getMetadata"]["data"]["reportingDimensions"][number];

export type OrganizationLockedPeriod =
  APIOutputs["organizations"]["getMetadata"]["data"]["lockedPeriods"][number];

export type OrganizationDefaultSetting =
  APIOutputs["organizations"]["getDefaultSettings"]["data"];

export const useOrganizationStore = defineStore("organization", () => {
  const authStore = useAuthStore();
  const { setCurrency, clearCurrency } = useCurrency();

  // state
  const orgId = ref("");
  const orgDetails = ref<OrganizationDetails | undefined>(undefined);
  const orgAccounts = ref<OrganizationAccount[]>([]);
  const orgMembers = ref<OrganizationMember[]>([]);
  const orgReceiptPaymentMethods = ref<OrganizationReceiptPaymentMethod[]>([]);
  const orgReportingDimensions = ref<OrganizationReportingDimension[]>([]);
  const orgLockedPeriods = ref<OrganizationLockedPeriod[]>([]);
  const defaultSettings = ref<OrganizationDefaultSetting>({
    fixedAssetsSettings: null,
    productsSettings: null,
    salesSettings: null,
    purchasingSettings: null,
    transactionsSettings: null,
    zatcaSettings: null,
  });

  const resetOrganization = () => {
    orgId.value = "";
    orgDetails.value = undefined;
    orgAccounts.value = [];
    orgMembers.value = [];
    orgReceiptPaymentMethods.value = [];
    orgReportingDimensions.value = [];
    orgLockedPeriods.value = [];
    defaultSettings.value = {
      fixedAssetsSettings: null,
      productsSettings: null,
      salesSettings: null,
      purchasingSettings: null,
      transactionsSettings: null,
      zatcaSettings: null,
    };
    clearCurrency();
  };

  const orgFlags = computed<PlansFlagsSchema>(
    () =>
      orgDetails.value?.subscriptionsHistory?.plans ?? {
        enableFixedAssetsModule: false,
        enableInventoryTracking: false,
        enableManualJournals: false,
        enableReportingDimensions: false,
        enableCustomFields: false,
        enablePricingPolicies: false,
        enableZatcaModule: false,
      }
  );

  const zatcaSettings = computed(() =>
    defaultSettings.value.zatcaSettings?.enabled === true
      ? {
          enabled: true,
          isProduction: defaultSettings.value.zatcaSettings?.isProduction,
          wentLiveAt:
            defaultSettings.value.zatcaSettings?.isProduction === true
              ? defaultSettings.value.zatcaSettings?.productionWentLiveAt
              : defaultSettings.value.zatcaSettings?.simulationWentLiveAt,
        }
      : {
          enabled: false,
          isProduction: false,
          wentLiveAt: null,
        }
  );

  const orgDefaultAccounts = computed(() => ({
    assetsAcquisition:
      defaultSettings.value?.fixedAssetsSettings
        ?.defaultAssetsAcquisitionAccountId,
    assetsDepreciation:
      defaultSettings.value?.fixedAssetsSettings?.defaultDepreciationAccountId,
    assetsAppreciation:
      defaultSettings.value?.fixedAssetsSettings?.defaultAppreciationAccountId,
    productsCogs: defaultSettings.value?.productsSettings?.defaultCogsAccountId,
    productsExpense:
      defaultSettings.value?.productsSettings?.defaultExpenseAccountId,
    productsRevenue:
      defaultSettings.value?.productsSettings?.defaultRevenueAccountId,
    stocktakeExpense:
      defaultSettings.value?.productsSettings?.defaultStocktakeExpenseAccountId,
    stocktakeRevenue:
      defaultSettings.value?.productsSettings?.defaultStocktakeRevenueAccountId,
    salesCash: defaultSettings.value?.salesSettings?.defaultCashAccountId,
    salesReceivables:
      defaultSettings.value?.salesSettings?.defaultReceivablesAccountId,
    purchasingCash:
      defaultSettings.value?.purchasingSettings?.defaultCashAccountId,
    purchasingPayables:
      defaultSettings.value?.purchasingSettings?.defaultPayablesAccountId,
  }));

  const fetchOrganizationDetails = async (id: string) => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.organizations.getOne.query({
        id,
      });

      orgId.value = result.data.id;
      orgDetails.value = result.data;
      setCurrency(result.data.currency);

      return result.data;
    } catch (error) {
      console.error("Error fetching organization details:", error);
    }
  };

  const fetchOrganizationMembers = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.organizationMembers.getAll.query({
        where: {
          status: "active",
        },
      });

      orgMembers.value = result.data;
    } catch (error) {
      console.error("Error fetching organization members:", error);
    }
  };

  const fetchOrganizationAccounts = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const { data } = await trpcClient.accounts.getAll.query();

      orgAccounts.value = data;

      return data;
    } catch (error) {
      console.error("Error fetching organization accounts:", error);
    }
  };

  const fetchOrganizationReceiptPaymentMethods = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.receiptPaymentMethods.getAll.query();

      orgReceiptPaymentMethods.value = result.data;
    } catch (error) {
      console.error(
        "Error fetching organization receipt payment methods:",
        error
      );
    }
  };

  const fetchReportingDimensions = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.reportingDimensions.getAll.query({});

      orgReportingDimensions.value = result.data;
    } catch (error) {
      console.error("Error fetching reporting dimensions:", error);
    }
  };

  const fetchOrganizationLockedPeriods = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const { lockedPeriods } = await trpcClient.lockedPeriods.getAll.query({});

      orgLockedPeriods.value = lockedPeriods;
    } catch (error) {
      console.error("Error fetching organization locked periods:", error);
    }
  };

  const fetchOrganizationSettings = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.organizations.getDefaultSettings.query(
        {}
      );

      defaultSettings.value = result.data;
    } catch (error) {
      console.error("Error fetching organization settings:", error);
    }
  };

  const fetchZatcaSettings = async () => {
    const { trpcClient } = useTrpcClient();
    try {
      const result = await trpcClient.zatca.getOneSettings.query();

      defaultSettings.value.zatcaSettings = result.data;
    } catch (error) {
      console.error("Error fetching ZATCA settings:", error);
      defaultSettings.value.zatcaSettings = null;
    }
  };

  const fetchOrganizationMetadata = async () => {
    try {
      const { trpcClient } = useTrpcClient();
      const { data: organization } =
        await trpcClient.organizations.getMetadata.query();

      orgMembers.value = organization.organizationMembers;
      orgReceiptPaymentMethods.value = organization.receiptPaymentMethods;
      orgReportingDimensions.value = organization.reportingDimensions;
      orgLockedPeriods.value = organization.lockedPeriods;
      defaultSettings.value = {
        fixedAssetsSettings: organization.fixedAssetsSettings,
        productsSettings: organization.productsSettings,
        salesSettings: organization.salesSettings,
        purchasingSettings: organization.purchasingSettings,
        transactionsSettings: organization.transactionsSettings,
        zatcaSettings: organization.zatcaSettings,
      };

      return organization;
    } catch (error) {
      console.error("Error fetching organization metadata:", error);
    }
  };

  const init = async (
    orgId: string
  ): Promise<{
    isInactiveOrg: boolean | null;
    isNetworkError: boolean;
    onboardingRedirect: boolean | null;
  }> => {
    try {
      resetOrganization();

      const [organization] = await Promise.all([
        fetchOrganizationDetails(orgId),
        authStore.getOrgAccessToken(orgId),
      ]);

      if (organization === undefined) {
        return {
          isInactiveOrg: null,
          isNetworkError: true,
          onboardingRedirect: null,
        };
      }

      const isActiveOrg = organization?.isActive === true;

      if (isActiveOrg) {
        await authStore.fetchUserRoles();
        const [, accounts] = await Promise.all([
          fetchOrganizationMetadata(),
          fetchOrganizationAccounts(),
        ]);

        const onboardingRedirect = accounts == null || accounts.length === 0;

        return {
          isInactiveOrg: !isActiveOrg,
          isNetworkError: false,
          onboardingRedirect,
        };
      }

      return {
        isInactiveOrg: !isActiveOrg,
        isNetworkError: false,
        onboardingRedirect: null,
      };
    } catch (error) {
      console.error(error);
      return {
        isInactiveOrg: null,
        isNetworkError: true,
        onboardingRedirect: null,
      };
    }
  };

  return {
    // state
    orgId,
    orgDetails,
    orgAccounts,
    orgMembers,
    orgReceiptPaymentMethods,
    orgReportingDimensions,
    orgLockedPeriods,
    // computed
    hydratedAccounts: orgAccounts,
    defaultSettings,
    orgDefaultAccounts,
    orgFlags,
    zatcaSettings,

    resetOrganization,
    fetchOrganizationAccounts,
    fetchReportingDimensions,
    fetchOrganizationDetails,
    fetchOrganizationMembers,
    fetchOrganizationReceiptPaymentMethods,
    fetchOrganizationLockedPeriods,
    fetchOrganizationSettings,
    fetchZatcaSettings,
    init,
  };
});
